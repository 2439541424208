import React, { useState, useEffect, useMemo, useCallback } from "react";
import SpiderChart from "./SpiderChart";
import BottomControls from "./BottomControls";
import "./Compare.css";

const Compare = ({ heuristicData, onDataPointClick, thirdPaneOpen }) => {
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [chartSize, setChartSize] = useState(50);

  useEffect(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        setSelectedPeriod(
          (prev) => (prev + 1) % (heuristicData?.datasets?.length || 1)
        );
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [isPlaying, heuristicData]);

  const handlePeriodChange = useCallback((event) => {
    setSelectedPeriod(Number(event.target.value));
    setIsPlaying(false);
  }, []);

  const handlePlayPause = useCallback(() => {
    setIsPlaying((prev) => !prev);
  }, []);

  const getPeriodLabel = useCallback((period) => {
    return `Release ${period + 1}`;
  }, []);

  const getPageData = useCallback(
    (pageId) => {
      if (!heuristicData || !heuristicData.datasets) return null;

      const currentData = heuristicData.datasets.find(
        (dataset) => dataset.label === pageId
      );

      if (!currentData) return null;

      return {
        labels: heuristicData.labels,
        datasets: [currentData],
      };
    },
    [heuristicData]
  );

  const sortedPages = useMemo(() => {
    if (!heuristicData || !heuristicData.datasets) return [];

    return heuristicData.datasets
      .map((dataset) => dataset.label)
      .sort((a, b) => a.localeCompare(b));
  }, [heuristicData]);

  return (
    <div
      className={`compare-container ${thirdPaneOpen ? "third-pane-open" : ""}`}
    >
      <div className="compare-chart-grid">
        {sortedPages.map((pageId) => {
          const pageData = getPageData(pageId);
          return (
            <div key={pageId} className="compare-chart-item">
              {pageData ? (
                <SpiderChart
                  key={`${pageId}`}
                  data={pageData}
                  title={`${pageId}`}
                  pageId={pageId}
                  size={chartSize}
                  isComparePage={true}
                  selectedPeriod={selectedPeriod}
                  onDataPointClick={onDataPointClick}
                />
              ) : (
                <div>No data available for {pageId}</div>
              )}
            </div>
          );
        })}
      </div>
      <BottomControls
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        selectedPeriod={selectedPeriod}
        setSelectedPeriod={setSelectedPeriod}
        heuristicData={heuristicData}
        chartSize={chartSize}
        setChartSize={setChartSize}
        isComparePage={true}
      />
    </div>
  );
};

export default React.memo(Compare);
