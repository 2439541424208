import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { debounce } from "lodash";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { heuristics } from "./utils/commonUtils";
import "./SpiderChart.css";
import { getScoreColor } from "./utils/ScoreColor";
import { calculateAverage } from "./utils/calculateAverage";
import ScoreWithPercentChange from "./ScoreWithPercentChange";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const SpiderChart = ({
  data,
  title,
  pageId,
  size = 50,
  isComparePage,
  selectedPeriod,
  onDataPointClick,
  previousData,
  isHomePage,
}) => {
  const chartRef = useRef(null);
  const [chartSize, setChartSize] = useState({ width: 0, height: 0 });
  const [tooltip, setTooltip] = useState({
    visible: false,
    content: "",
    x: 0,
    y: 0,
  });
  const [activePoint, setActivePoint] = useState(null);

  const updateSize = useCallback(() => {
    if (!isComparePage) {
      const vw = Math.max(
        document.documentElement.clientWidth + 100 || 0,
        window.innerWidth || 0
      );
      const vh = Math.max(
        document.documentElement.clientHeight + 100 || 0,
        window.innerHeight || 0
      );
      const minDimension = Math.min(vw, vh);
      const newSize = (minDimension * size) / 100;
      setChartSize({ width: newSize, height: newSize });
    } else if (chartRef.current) {
      const { width, height } = chartRef.current.getBoundingClientRect();
      setChartSize({ width, height });
    }
  }, [size, isComparePage]);

  const debouncedUpdateSize = useCallback(
    debounce(() => {
      updateSize();
    }, 50),
    [updateSize]
  );

  useEffect(() => {
    window.addEventListener("resize", debouncedUpdateSize);
    updateSize();

    return () => {
      window.removeEventListener("resize", debouncedUpdateSize);
    };
  }, [debouncedUpdateSize, updateSize]);

  const averageScores = data.datasets.map(calculateAverage);

  // Calculate previous average scores only if on the home page
  // const findPreviousDataset = (previousData, datasetLabel) => {
  //   if (!previousData || typeof previousData !== "object") {
  //     return null;
  //   }

  //   const previousDataValues = Object.values(previousData);
  //   if (!previousDataValues.length) {
  //     return null;
  //   }

  //   return previousDataValues.find(
  //     (data) => data?.datasets?.[0]?.label === datasetLabel
  //   );
  // };

  const renderAverageScores = () => {
    // Create array of objects containing both score and dataset info for sorting
    const scoreDataPairs = averageScores.map((score, index) => ({
      score,
      dataset: data.datasets[index],
    }));

    // Sort alphabetically by label
    scoreDataPairs.sort((a, b) =>
      a.dataset.label.localeCompare(b.dataset.label)
    );

    // Map and render the sorted pairs
    return scoreDataPairs.map((pair, index) => {
      const { score, dataset } = pair;
      const label = `${dataset.label}`;

      return (
        <div
          key={index}
          className={
            !isComparePage
              ? "average-score"
              : "average-score compare-average-score"
          }
        >
          <div className="label-container">
            <div
              className="color-square"
              style={{ backgroundColor: dataset.borderColor }}
            ></div>
            {isComparePage ? (
              <label>{label}</label>
            ) : (
              <label>{isHomePage ? label + " " : "Product Area Score"}</label>
            )}
          </div>
          <ScoreWithPercentChange
            score={score}
            label={label}
            data={data}
            previousData={previousData}
            index={data.datasets.findIndex((d) => d.label === dataset.label)}
            isHomePage={isHomePage}
            pageId={dataset.id}
            scorePage={true}
          />
        </div>
      );
    });
  };

  const modifiedData = useMemo(() => {
    return {
      ...data,
      datasets: data.datasets
        .filter((dataset) => !isHomePage || dataset.label !== "Compare")
        .map((dataset) => ({
          ...dataset,
          borderColor: dataset.borderColor || "rgba(0, 0, 0, 1)",
          backgroundColor: "rgba(0, 0, 0, 0)",
          pointRadius: 10,
          pointHoverRadius: 12,
          pointBackgroundColor:
            dataset.pointBackgroundColor ||
            dataset.borderColor ||
            "rgba(0, 0, 0, 1)",
          pointBorderColor: dataset.pointBorderColor || "#fff",
          pointHoverBackgroundColor:
            dataset.pointHoverBackgroundColor || "#fff",
          pointHoverBorderColor:
            dataset.pointHoverBorderColor ||
            dataset.borderColor ||
            "rgba(0, 0, 0, 1)",
        })),
    };
  }, [data, isHomePage]);

  const handleDataPointClick = useCallback(
    (event, elements) => {
      if (elements.length > 0) {
        const { datasetIndex, index } = elements[0];
        const dataPoint = {
          label: data.labels[index],
          value: data.datasets[datasetIndex].data[index],
          pageLabel: data.datasets[datasetIndex].label,
          description: heuristics[index]?.description || "",
          datasetIndex,
          index,
          // Access notes and links from datasets
          notes: data.datasets[datasetIndex]?.notes || {},
          links: data.datasets[datasetIndex]?.links || {},
          color: getScoreColor(data.datasets[datasetIndex].data[index]),
          id: data.datasets[datasetIndex].id,
        };
        onDataPointClick(dataPoint);
      }
    },
    [data, onDataPointClick]
  );

  const options = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: function (context) {
            const { chart, tooltip } = context;

            if (tooltip.opacity === 0) {
              setTooltip((prev) => {
                if (!prev.visible) {
                  return prev;
                }
                return { ...prev, visible: false };
              });
              return;
            }

            const { offsetLeft: positionX, offsetTop: positionY } =
              chart.canvas;

            let x = positionX + tooltip.caretX;
            let y = positionY + tooltip.caretY;

            const chartWidth = chart.width;
            const chartHeight = chart.height;

            if (tooltip.caretX > chartWidth / 2) {
              x -= 20;
            }

            if (tooltip.caretY > chartHeight / 2) {
              y -= 140;
            } else {
              y += 20;
            }

            const datasetLabel = tooltip.dataPoints[0].dataset.label;
            const value = tooltip.dataPoints[0].formattedValue;
            const heuristic = heuristics[tooltip.dataPoints[0].dataIndex];

            const newTooltip = {
              visible: true,
              content: `${datasetLabel}: ${value} <p>Heuristic: ${heuristic.label}</p><p>Description: ${heuristic.description}</p>`,
              x: x + "px",
              y: y + "px",
            };

            setTooltip((prevTooltip) => {
              if (
                prevTooltip.visible === newTooltip.visible &&
                prevTooltip.content === newTooltip.content &&
                prevTooltip.x === newTooltip.x &&
                prevTooltip.y === newTooltip.y
              ) {
                return prevTooltip;
              }
              return newTooltip;
            });
          },
        },
        datalabels: {
          formatter: (value) => value.toFixed(1),
          color: "black",
          font: {
            size: 10,
            weight: "bold",
          },
          anchor: "center",
          align: "center",
          offset: 0,
        },
      },
      scales: {
        r: {
          beginAtZero: true,
          min: 0,
          max: 5,
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
          pointLabels: {
            display: false,
          },
          angleLines: {
            display: false,
          },
        },
      },
      layout: {
        padding: {
          top: 10,
          bottom: 10,
          left: 10,
          right: 10,
        },
      },
      onHover: (event, chartElement) => {
        if (chartElement.length === 1) {
          const { datasetIndex, index } = chartElement[0];
          setActivePoint((prevActivePoint) => {
            if (
              prevActivePoint &&
              prevActivePoint.datasetIndex === datasetIndex &&
              prevActivePoint.index === index
            ) {
              return prevActivePoint;
            }
            return { datasetIndex, index };
          });
        } else {
          setActivePoint((prevActivePoint) => {
            if (prevActivePoint !== null) {
              return null;
            }
            return prevActivePoint;
          });
        }
      },
      onClick: handleDataPointClick,
    };
  }, [data, heuristics, setTooltip, setActivePoint, handleDataPointClick]);

  const plugins = useMemo(() => {
    return [
      ChartDataLabels,
      {
        id: "customGridLines",
        beforeDraw: (chart) => {
          const { ctx, chartArea, scales } = chart;
          const { top, bottom, left, right } = chartArea;
          const centerX = (left + right) / 2;
          const centerY = (top + bottom) / 2;
          const radius = Math.min(chartArea.width, chartArea.height) / 2;

          ctx.save();

          for (let i = 0.5; i <= scales.r.max; i += 0.5) {
            const ratio = i / scales.r.max;
            ctx.beginPath();
            ctx.arc(centerX, centerY, radius * ratio, 0, 2 * Math.PI);

            if (i === scales.r.max) {
              ctx.strokeStyle = "rgba(0, 0, 0, 1)";
              ctx.setLineDash([]);
              ctx.lineWidth = 2;
            } else if (i % 1 === 0) {
              ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
              ctx.setLineDash([]);
              ctx.lineWidth = 1;
            } else {
              ctx.strokeStyle = "rgba(0, 0, 0, 0.5)";
              ctx.setLineDash([2, 2]);
              ctx.lineWidth = 1;
            }

            ctx.stroke();
          }

          const numPoints = chart.data.labels.length;
          for (let i = 0; i < numPoints; i++) {
            const angle = (i / numPoints) * 2 * Math.PI - Math.PI / 2;
            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.lineTo(
              centerX + Math.cos(angle) * radius,
              centerY + Math.sin(angle) * radius
            );
            ctx.strokeStyle = "rgba(0, 0, 0, 0.2)";
            ctx.lineWidth = 1;
            ctx.stroke();
          }

          ctx.restore();
        },
      },
      {
        id: "customTicks",

        afterDraw: (chart) => {
          const { ctx, chartArea, scales } = chart;
          const { top, bottom, left, right } = chartArea;
          const centerX = (left + right) / 2;
          const centerY = (top + bottom) / 2;
          const radius = Math.min(chartArea.width, chartArea.height) / 2;

          ctx.save();
          ctx.font = "bold 11px Arial";
          ctx.fillStyle = "#000";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";

          for (let i = 1; i <= scales.r.max; i++) {
            const ratio = i / scales.r.max;
            const angle = Math.PI / 2;
            const x = centerX - Math.cos(angle) * radius * ratio;
            const y = centerY - Math.sin(angle) * radius * ratio;
            ctx.fillText(i.toString(), x, y);
          }

          ctx.restore();
        },
      },
      {
        id: "customPointStyles",
        afterDraw: (chart) => {
          const { ctx } = chart;
          chart.data.datasets.forEach((dataset, datasetIndex) => {
            const meta = chart.getDatasetMeta(datasetIndex);
            meta.data.forEach((point, index) => {
              const { x, y } = point.getCenterPoint();
              const radius = point.options.radius;

              if (
                activePoint &&
                activePoint.datasetIndex === datasetIndex &&
                activePoint.index === index
              ) {
                ctx.beginPath();
                ctx.arc(x, y, radius + 4, 0, 2 * Math.PI);
                ctx.fillStyle = "rgba(255, 255, 0, 0.3)";
                ctx.fill();
                ctx.strokeStyle = "yellow";
                ctx.lineWidth = 2;
                ctx.stroke();
              }

              if (
                point.active &&
                (!activePoint ||
                  activePoint.datasetIndex !== datasetIndex ||
                  activePoint.index !== index)
              ) {
                ctx.beginPath();
                ctx.arc(x, y, radius + 2, 0, 2 * Math.PI);
                ctx.fillStyle = "rgba(255, 255, 255, 0.3)";
                ctx.fill();
              }
            });
          });
        },
      },
    ];
  }, [activePoint]);

  return (
    <>
      <div className="metrics">{renderAverageScores()}</div>

      <div
        className={isComparePage ? "compare-spider-chart" : "spider-chart"}
        ref={chartRef}
        style={
          isComparePage
            ? { width: "100%", height: "100%" }
            : {
                width: `${chartSize.width}px`,
                height: `${chartSize.height}px`,
                transition: "width 0.1s, height 0.1s",
              }
        }
      >
        <div className="chart-header"></div>
        <div
          className="chart-container"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <Radar
            data={modifiedData}
            options={options}
            plugins={plugins}
            width={chartSize.width}
            height={chartSize.height}
          />
          {!isComparePage &&
            heuristics.map((heuristic, index) => {
              const angle =
                (index / heuristics.length) * 2 * Math.PI - Math.PI / 2;
              const labelRadius = 55;
              const x = 50 + labelRadius * Math.cos(angle);
              const y = 50 + labelRadius * Math.sin(angle);
              return (
                <div
                  key={index}
                  className="heuristic-label"
                  style={{
                    position: "absolute",
                    left: `${x}%`,
                    top: `${y}%`,
                    transform: `translate(${
                      angle > Math.PI / 2 || angle < -Math.PI / 2
                        ? "-100%"
                        : "0"
                    }, -50%)`,
                  }}
                >
                  {heuristic.label}
                </div>
              );
            })}
        </div>
        {tooltip.visible && (
          <div
            className="custom-tooltip"
            style={{ left: tooltip.x, top: tooltip.y }}
            dangerouslySetInnerHTML={{ __html: tooltip.content }}
          />
        )}
      </div>
    </>
  );
};

export default SpiderChart;
