import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { releases, isReleasesLoaded } from "./utils/commonUtils";
import "./ToggleSwitch.css";

const ToggleSwitch = ({
  isHomePage,
  currentPage,
  currentView,
  updateView,
  userInfo,
  selectedPeriod,
}) => {
  const [isLoading, setIsLoading] = useState(!isReleasesLoaded);
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = userInfo?.isAdmin || false;
  const isResearcher = userInfo?.isResearcher || false;

  // Initialize view from URL on mount
  useEffect(() => {
    const url = new URL(window.location.href);
    const viewParam = url.searchParams.get("view");
    if (viewParam) {
      updateView(viewParam);
    }
  }, [updateView]);

  const handleViewChange = (view) => {
    // Update URL with query parameter
    const url = new URL(window.location.href);
    url.searchParams.set("view", view);
    window.history.pushState({}, "", url);

    // Update view state
    updateView(view);
  };

  const getPeriodLabel = (period) => {
    return releases[period] ? `Release ${releases[period]}` : "Unknown Release";
  };

  const handleEditClick = () => {
    navigate("/data-entry", {
      state: { pageId: location.pathname.split("/").pop() },
    });
  };

  return (
    <div className="header-bar">
      <h1>
        {currentPage} <span>{getPeriodLabel(selectedPeriod)}</span>
      </h1>
      <div className="toggleswitch">
        <button
          onClick={() => handleViewChange("overview")}
          className={`toggle-link ${
            currentView === "overview" ? "active" : ""
          }`}
        >
          USE Impact
        </button>
        <button
          onClick={() => handleViewChange("table")}
          className={`toggle-link ${currentView === "table" ? "active" : ""}`}
        >
          USE Scores
        </button>

        <button
          onClick={() => handleViewChange("scoreTable")}
          className={`toggle-link ${
            currentView === "scoreTable" ? "active" : ""
          }`}
        >
          USE Grades
        </button>
        <button
          onClick={() => handleViewChange("treemap")}
          className={`toggle-link ${currentView === "treemap" ? "active" : ""}`}
        >
          Stack Ranked
        </button>
        <button
          onClick={() => handleViewChange("notes")}
          className={`toggle-link ${currentView === "notes" ? "active" : ""}`}
        >
          Evaluation Notes
        </button>
        <button
          onClick={() => handleViewChange("links")}
          className={`toggle-link ${currentView === "links" ? "active" : ""}`}
        >
          JIRA Links
        </button>
      </div>
      <div className="actions">
        {!isHomePage && (isAdmin || isResearcher) && (
          <button className="secondary" onClick={handleEditClick}>
            <FontAwesomeIcon icon={faEdit} /> Edit
          </button>
        )}
        {/* <button className="secondary">
          <FontAwesomeIcon icon={faPlus} /> Add
        </button> */}
      </div>
    </div>
  );
};

export default ToggleSwitch;
