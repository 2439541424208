// Lightbox.js
import React, { useState, useEffect } from "react";
import LoadingDots from "./components/LoadingDots";
import "./Lightbox.css";
import uselyLogo from "./images/usely-logo.svg";

const Lightbox = ({
  isVisible,
  onClose,
  message,
  secondaryMessage,
  isLoading,
  showCloseButton,
  showCloneSelect,
  cloneSelect,
  onConfirm,
  onCancel,
  onUpdate,
  showUpdateButton,
  showConfirmButton, // New prop for showing confirm button
  confirmButtonText, // Text for confirm button (e.g., "Delete" or "Confirm")
  cloneInProgress,
  uploadStatus,
}) => {
  const [displayedMessage, setDisplayedMessage] = useState(message);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (!secondaryMessage) {
      setDisplayedMessage(message);
      setIsTransitioning(false);
    }

    if (secondaryMessage) {
      setIsTransitioning(true);
      const timer = setTimeout(() => {
        setDisplayedMessage(secondaryMessage);
        setIsTransitioning(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [message, secondaryMessage]);

  const handleClose = () => {
    if (uploadStatus === "success") {
      window.location.reload();
    } else {
      onClose();
    }
  };

  if (!isVisible) return null;

  return (
    <div className="lightbox">
      <div className="lightbox-logo-contain">
        <img src={uselyLogo} alt="Usely" className="logo" />
      </div>
      <div className="lightbox-content">
        {isLoading && (
          <div className="lightbox-loading">
            <LoadingDots />
          </div>
        )}
        <p className={`message ${isTransitioning ? "fade-out" : "fade-in"}`}>
          {message}
        </p>

        {showCloneSelect && (
          <>
            {cloneSelect}
            <button
              type="button"
              onClick={onConfirm}
              className="confirm-clone-btn"
              disabled={isLoading || cloneInProgress}
            >
              Clone Data
            </button>
          </>
        )}

        {/* New confirm button for delete/confirm actions */}
        {showConfirmButton && !isLoading && (
          <button
            type="button"
            onClick={onConfirm}
            className="confirm-button"
            disabled={isLoading}
          >
            {confirmButtonText || "Confirm"}
          </button>
        )}

        {showUpdateButton && !isLoading && (
          <button
            type="button"
            onClick={onUpdate}
            className="update-button"
            disabled={isLoading || cloneInProgress}
          >
            Update Data
          </button>
        )}

        {/* Only show cancel when not cloning or updating */}
        {!isLoading &&
          !showConfirmButton && // Don't show cancel with confirm button
          !showCloseButton && ( // Don't show cancel with done button
            <button
              type="button"
              onClick={onCancel}
              className="cancel-button"
              disabled={cloneInProgress && !showUpdateButton}
            >
              Cancel
            </button>
          )}
        {!isLoading && showCloseButton && (
          <button
            type="button"
            onClick={handleClose}
            className="close-lightbox-btn"
          >
            Done
          </button>
        )}
      </div>
    </div>
  );
};

export default Lightbox;
