import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "./firebase";
import UserApprovalsSwitch from "./UserApprovalsSwitch";
import "./UserApproval.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const UserApproval = ({ userInfo }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [currentView, setCurrentView] = useState("approved");
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [approvedCount, setApprovedCount] = useState(0);
  const [nonApprovedCount, setNonApprovedCount] = useState(0);
  const auth = getAuth();
  const currentUserId = auth.currentUser?.uid;

  const toggleDropdown = (userId) => {
    setDropdownOpen((prev) => (prev === userId ? null : userId));
  };

  // Utility function to format date
  const formatDate = (timestamp) => {
    if (!timestamp) return "No date available";
    if (timestamp.toDate) {
      return timestamp.toDate().toLocaleDateString();
    }
    if (timestamp instanceof Date) {
      return timestamp.toLocaleDateString();
    }
    if (typeof timestamp === "number") {
      return new Date(timestamp).toLocaleDateString();
    }
    return "Invalid date";
  };

  // Check if current user is admin or super admin
  const checkAdminStatus = async () => {
    try {
      const userDoc = await getDoc(doc(db, "users", currentUserId));
      const userData = userDoc.data();
      setIsAdmin(userData?.isAdmin || false);
      setIsSuperAdmin(userData?.superAdmin || false);
    } catch (error) {
      console.error("Error checking admin status:", error);
      setError("You do not have permission to view this page");
    }
  };

  // Fetch users based on the currentView state
  const fetchUsers = async () => {
    if (!isAdmin) {
      setError("You do not have permission to view this page");
      return;
    }

    setError(null);

    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      const fetchedUsers = [];
      let approvedUsersCount = 0;
      let nonApprovedUsersCount = 0;

      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        // Add a derived role property for easier sorting
        let role = "User";
        if (userData.superAdmin) {
          role = "Super Admin";
        } else if (userData.isAdmin) {
          role = "Admin";
        } else if (userData.isResearcher) {
          role = "Researcher";
        } else {
          userData.isUser = true; // Ensure user role is set to User by default
        }

        // Count users by approval status
        if (userData.isApproved) {
          approvedUsersCount++;
        } else {
          nonApprovedUsersCount++;
        }

        if (
          (currentView === "nonApproved" && !userData.isApproved) ||
          (currentView === "approved" && userData.isApproved)
        ) {
          fetchedUsers.push({ id: doc.id, ...userData, role });
        }
      });

      setApprovedCount(approvedUsersCount);
      setNonApprovedCount(nonApprovedUsersCount);
      setUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
      setError("Error fetching users. Please check your permissions.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAdminStatus();
  }, []);

  useEffect(() => {
    if (isAdmin) {
      fetchUsers();
    }
  }, [isAdmin, currentView]);

  // Approve user function
  const approveUser = async (userId) => {
    try {
      await updateDoc(doc(db, "users", userId), {
        isApproved: true,
        isUser: true, // Set user role by default
      });
      fetchUsers();
    } catch (error) {
      console.error("Error approving user:", error);
      setError(error.message);
    }
  };

  // Un-approve user function (for admins and superAdmins)
  const unapproveUser = async (userId, userEmail) => {
    try {
      const confirmUnapprove = window.confirm(
        `Are you sure you want to un-approve ${userEmail}? This will also remove admin, researcher, and super admin rights.`
      );
      if (!confirmUnapprove) return;

      await updateDoc(doc(db, "users", userId), {
        isApproved: false,
        isAdmin: false,
        superAdmin: false,
        isResearcher: false,
        isUser: true, // Set to user role by default when unapproved
      });
      alert(
        `User ${userEmail} has been successfully un-approved and their roles have been removed.`
      );
      fetchUsers(); // Refresh user list
    } catch (error) {
      console.error("Error un-approving user:", error);
      setError("Error un-approving user. Please try again.");
    }
  };

  // Function to assign a specific role and clear all other roles
  const assignRole = async (userId, role, userEmail) => {
    try {
      let updatedData = {
        isUser: false,
        isAdmin: false,
        isResearcher: false,
        superAdmin: false,
      };

      // Set only the selected role
      updatedData[role] = true;

      await updateDoc(doc(db, "users", userId), updatedData);
      alert(`User ${userEmail} has been successfully updated to ${role}.`);
      fetchUsers(); // Refresh user list
    } catch (error) {
      console.error(`Error assigning role (${role}) to user:`, error);
      setError(`Error assigning role to user. Please try again.`);
    }
  };

  // Delete user function (for superAdmins only)
  const deleteUser = async (userId, userEmail) => {
    try {
      const confirmDelete = window.confirm(
        `You are about to delete ${userEmail}. Are you sure?`
      );
      if (!confirmDelete) return;

      await deleteDoc(doc(db, "users", userId));
      alert(`User ${userEmail} has been successfully deleted.`);
      fetchUsers(); // Refresh user list after deletion
    } catch (error) {
      console.error("Error deleting user:", error);
      setError("Error deleting user. Please try again.");
    }
  };

  // Handle column sorting
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedUsers = React.useMemo(() => {
    let sortableUsers = [...users];
    if (sortConfig.key !== null) {
      sortableUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableUsers;
  }, [users, sortConfig]);

  if (error) return <div className="error-message">{error}</div>;
  if (!isAdmin) return <div>Access Denied: Admin privileges required</div>;

  return (
    <>
      <UserApprovalsSwitch
        currentPage={`Users (${approvedCount})`}
        currentView={currentView}
        updateView={setCurrentView}
        approvedCount={approvedCount}
        nonApprovedCount={nonApprovedCount}
      />

      {sortedUsers.length === 0 ? (
        <p>No users found for this category</p>
      ) : (
        <div className="user-approval-table-container">
          <table className="user-approval-table">
            <thead>
              <tr>
                <th onClick={() => handleSort("email")}>
                  Email{" "}
                  <FontAwesomeIcon
                    icon={
                      sortConfig.key === "email" &&
                      sortConfig.direction === "asc"
                        ? faChevronUp
                        : faChevronDown
                    }
                  />
                </th>
                {currentView === "approved" && (
                  <th onClick={() => handleSort("role")}>
                    User Role{" "}
                    <FontAwesomeIcon
                      icon={
                        sortConfig.key === "role" &&
                        sortConfig.direction === "asc"
                          ? faChevronUp
                          : faChevronDown
                      }
                    />
                  </th>
                )}
                <th onClick={() => handleSort("createdAt")}>
                  Date Joined{" "}
                  <FontAwesomeIcon
                    icon={
                      sortConfig.key === "createdAt" &&
                      sortConfig.direction === "asc"
                        ? faChevronUp
                        : faChevronDown
                    }
                  />
                </th>
                <th>{currentView === "approved" ? "Unapprove" : "Approve"}</th>
                {currentView === "approved" && <th>Manage Roles</th>}
                <th>Delete User</th>
              </tr>
            </thead>
            <tbody>
              {sortedUsers.map((user) => (
                <tr
                  key={user.id}
                  className={
                    user.id === currentUserId ? "logged-in-user-row" : ""
                  }
                >
                  <td>{user.email}</td>
                  {currentView === "approved" && (
                    <td>
                      {user.role === "Super Admin" ? (
                        <span className="super-admin-badge">Super Admin</span>
                      ) : user.role === "Admin" ? (
                        <span className="admin-badge">Admin</span>
                      ) : user.role === "Researcher" ? (
                        <span className="researcher-badge">Researcher</span>
                      ) : (
                        <span className="user-badge">User</span>
                      )}
                    </td>
                  )}
                  <td>{formatDate(user.createdAt)}</td>
                  <td>
                    {currentView === "nonApproved" && (
                      <button
                        onClick={() => approveUser(user.id)}
                        className="approve-button"
                      >
                        Approve User
                      </button>
                    )}
                    {currentView === "approved" &&
                      (isSuperAdmin || isAdmin) && (
                        <button
                          className="unapprove-button"
                          onClick={() => unapproveUser(user.id, user.email)}
                          disabled={
                            user.id === currentUserId ||
                            (isAdmin && user.role === "Super Admin")
                          }
                        >
                          Unapprove User
                        </button>
                      )}
                  </td>
                  {currentView === "approved" && (
                    <td>
                      {(isSuperAdmin || isAdmin) && (
                        <div
                          className={`dropdown ${
                            dropdownOpen === user.id ? "open" : ""
                          }`}
                        >
                          <button
                            className="dropdown-toggle"
                            onClick={() => toggleDropdown(user.id)}
                            disabled={user.id === currentUserId}
                          >
                            Manage Roles ▾
                          </button>
                          {dropdownOpen === user.id && (
                            <div className="dropdown-content">
                              {!user.isAdmin && (
                                <button
                                  onClick={() =>
                                    assignRole(user.id, "isAdmin", user.email)
                                  }
                                >
                                  Admin
                                </button>
                              )}
                              {!user.isResearcher && (
                                <button
                                  onClick={() =>
                                    assignRole(
                                      user.id,
                                      "isResearcher",
                                      user.email
                                    )
                                  }
                                >
                                  Researcher
                                </button>
                              )}
                              {!user.isUser && (
                                <button
                                  onClick={() =>
                                    assignRole(user.id, "isUser", user.email)
                                  }
                                >
                                  User
                                </button>
                              )}
                              {isSuperAdmin && !user.superAdmin && (
                                <button
                                  onClick={() =>
                                    assignRole(
                                      user.id,
                                      "superAdmin",
                                      user.email
                                    )
                                  }
                                >
                                  Super Admin
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </td>
                  )}
                  <td>
                    {isSuperAdmin && (
                      <button
                        className="delete-user-button"
                        onClick={() => deleteUser(user.id, user.email)}
                        disabled={user.id === currentUserId}
                      >
                        Delete User
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default UserApproval;
