import React from "react";
import uselyScreens from "./images/uselyapp-group-3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlask, faBrain, faHeart } from "@fortawesome/free-solid-svg-icons";

const Method = () => (
  <section className="method">
    <h2>Real stories, real data with Usely AI</h2>
    {/* <p>With Usely, we equip design, HR, product, salee, user research or any team
      with rich datavisualization and presentation tools to gather scientific
      insights that rigorously assess and elevate your progress with any
      keymetric or milestone over time. </p> */}
    {/* <p>
      With Usely, we empower every team—from design and HR to product, sales,
      and user research—with robust data visualization and presentation tools
      that turn scientific data into actionable insights. The Usely platform
      helps you rigorously track your teams progress accross your desired
      success metrics and evaluate performance across any key metric or
      milestone over time, ensuring your organization is always one step ahead.
    </p> */}

    <p>
      Usely AI empowers teams to turn your data into action. Track progress on
      key metrics and evaluate performance over time with impactful data
      visualizations. Usely AI keeps your teams on target and ahead in a
      measurable, scientific way.
    </p>
    <img src={uselyScreens} alt="Usely" />
    <div className="method-stats">
      <div className="statcontain">
        <div className="method-section-header">
          <div className="stat">
            <FontAwesomeIcon icon={faBrain} className="method-section-icon" />
            <h3>Usely GenAI</h3>
          </div>
          <p>
            Usely AI is freakishly smart. Empower your teams with the ability to
            track progress on key metrics and evaluate performance over time.
            Usely AI can help you determine where to focus and how to improve.
          </p>
        </div>
        <div className="method-section-header">
          <div className="stat">
            <FontAwesomeIcon icon={faHeart} className="method-section-icon" />
            <h3>Beautiful Data</h3>
          </div>
          <p>
            Experience your data like never before. Usely Platform transforms
            complex metrics and insights into stunning, easy-to-understand
            visualizations over time. Progress metrics give you the scienfitic
            data you need to tell the real story.
          </p>
        </div>
        <div className="method-section-header">
          <div className="stat">
            <FontAwesomeIcon icon={faFlask} className="method-section-icon" />
            <h3>Art + Science </h3>
          </div>
          <p>
            Harness the power of rigorous scientific methods to accurately score
            and monitor any success metrics or key milestones for your company
            or business group. Our visual tools plus powerful AI empower your
            teams to quickly grasp key findings and track progress over time.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default Method;
