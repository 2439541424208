// TestimonialsSection.js
import React from "react";

const TestimonialsSection = () => (
  <section className="testimonials">
    <h2>❤️ People Love Usely AI</h2>

    <div className="testimonials-content">
      <div className="testimonial">
        {/* <img
          src="https://www.adobe.com/federal/assets/svgs/adobe-logo.svg"
          alt="Adobe"
        /> */}
        <p>
          {/* "Usely transformed our approach to usability. The scientific insights
          have been invaluable in refining our users experience. The data
          visualizations made it easy for our team to understand friction points
          and act quickly to resolve them." */}
          "Usely allows us to see what we are doing right, but more importantly
          what we are doing wrong—at scale—allowing us to fix it fast."
        </p>
        <p>SVP of Engineering</p>
      </div>
      <div className="testimonial">
        {/* <img
          src="https://www.adobe.com/federal/assets/svgs/adobe-logo.svg"
          alt="Adobe"
        /> */}
        <p>
          "Usely has elevated our software's usability to new heights and allows
          us to track our imporvements release over release. Daily, I ask Usely
          AI to tell me how we are progressing to infrom the company and board!
          It's amazing."
        </p>
        <p>VP Of Design</p>
      </div>
    </div>
  </section>
);

export default TestimonialsSection;
