import React, { useState, useEffect } from "react";
import { collection, getDocs, setDoc, doc } from "firebase/firestore";
import { db } from "./firebase";
import { useNavigate } from "react-router-dom";
import "./AddRelease.css"; // We'll create this CSS file later
import Lightbox from "./Lightbox";

const AddRelease = () => {
  const [newRelease, setNewRelease] = useState("");
  const [clonePrevious, setClonePrevious] = useState(false);
  const [previousReleaseVersion, setPreviousReleaseVersion] = useState("");
  const [isLightboxVisible, setIsLightboxVisible] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [lightboxMessage, setLightboxMessage] = useState(
    "Adding new release..."
  );
  const [cloneMessage, setCloneMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const getPreviousRelease = async () => {
      const releasesCollection = collection(db, "releases");
      const releasesSnapshot = await getDocs(releasesCollection);
      const releases = releasesSnapshot.docs.map((doc) => doc.id);

      const sortedReleases = releases.sort((a, b) => compareVersions(b, a));
      const previousRelease = sortedReleases[0];
      setPreviousReleaseVersion(previousRelease || "");
    };

    getPreviousRelease();
  }, []);

  function compareVersions(a, b) {
    // Split version strings into parts
    const aParts = a.split(".").map(Number);
    const bParts = b.split(".").map(Number);

    // Compare each part
    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const aVal = aParts[i] || 0;
      const bVal = bParts[i] || 0;

      if (aVal > bVal) return 1;
      if (aVal < bVal) return -1;
    }

    return 0;
  }

  const getPreviousReleaseData = async () => {
    const releasesCollection = collection(db, "releases");
    const releasesSnapshot = await getDocs(releasesCollection);
    const releases = releasesSnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));

    // Sort releases in descending order
    const sortedReleases = releases.sort((a, b) => compareVersions(b.id, a.id)); // Reverse sort

    // Find first release less than new release
    const previousRelease = sortedReleases.find(
      (release) => compareVersions(release.id, newRelease) < 0
    );

    return previousRelease?.data || null;
  };

  const handleAddRelease = async (e) => {
    e.preventDefault();
    setIsLightboxVisible(true);
    setUploadStatus("uploading");
    setLightboxMessage("Creating new release... Hang tight!");

    const sanitizedNewRelease = newRelease.trim();
    const invalidChars = /[#$\[\]]/;
    const validReleaseFormat = /^\d+(\.\d+)?$/;

    if (
      sanitizedNewRelease &&
      !invalidChars.test(sanitizedNewRelease) &&
      validReleaseFormat.test(sanitizedNewRelease)
    ) {
      try {
        let newReleaseData = {};

        if (clonePrevious) {
          setCloneMessage(
            `Cloning data from release ${previousReleaseVersion}...`
          );
          // If cloning, get previous release data
          newReleaseData = await getPreviousReleaseData();
          if (!newReleaseData) {
            setIsLightboxVisible(false);
            alert("No previous release found to clone.");
            return;
          }
        } else {
          // Original code for creating new release data
          const pagesCollection = collection(db, "pages");
          const pagesSnapshot = await getDocs(pagesCollection);
          const pagesData = pagesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          pagesData.forEach((page) => {
            const pageId = page.id;
            const pageTitle = page.title;
            newReleaseData[pageId] = {
              labels: [
                "Visibility of System Status",
                "Match between system and the real world",
                "User Control and Freedom",
                "Consistency and Standards",
                "Error Prevention",
                "Recognition rather than recall",
                "Flexibility and efficiency of use",
                "Aesthetic and minimalist design",
                "Help users recognize, diagnose, and recover from errors",
                "Help and documentation",
              ],
              datasets: [
                {
                  label: pageTitle,
                  data: new Array(10).fill(0),
                  backgroundColor: page.color + "33",
                  borderColor: page.color,
                  fill: false,
                  tension: 0.1,
                },
              ],
            };
          });
        }

        const releaseDocRef = doc(db, "releases", sanitizedNewRelease);
        await setDoc(releaseDocRef, newReleaseData);

        setLightboxMessage("Release added successfully!");
        if (clonePrevious) {
          setCloneMessage("Data cloned successfully!");
        }

        setUploadStatus("success");
      } catch (error) {
        console.error("Error adding new release:", error);
        setLightboxMessage("Error adding release. Please try again.");
        if (clonePrevious) {
          setCloneMessage("Clone data failed.");
        }

        setUploadStatus("error");
      }
    } else {
      setIsLightboxVisible(false);
      alert("Invalid release number. Please avoid using ., #, $, [, or ].");
    }
  };

  return (
    <div className="add-release-container">
      <form onSubmit={handleAddRelease} className="add-release-form">
        <input
          type="text"
          className="release-input"
          value={newRelease}
          onChange={(e) => setNewRelease(e.target.value)}
          placeholder="New Release Number"
          required
        />
        <div className="clone-option">
          <input
            type="checkbox"
            id="clonePrevious"
            checked={clonePrevious}
            onChange={(e) => setClonePrevious(e.target.checked)}
          />
          <label htmlFor="clonePrevious">
            Clone data from previous release{" "}
            {previousReleaseVersion && `(${previousReleaseVersion})`}
          </label>
        </div>
        <button type="submit">Add New Release</button>
      </form>

      <Lightbox
        isVisible={isLightboxVisible}
        onClose={() => setIsLightboxVisible(false)}
        message={lightboxMessage}
        secondaryMessage={cloneMessage}
        isLoading={uploadStatus === "uploading"}
        showCloseButton={uploadStatus !== "uploading"}
        uploadStatus={uploadStatus} // Add this line to pass the uploadStatus
      />
    </div>
  );
};

export default AddRelease;
