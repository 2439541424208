// FeatureBreakOut.js
import React from "react";
import heuristics from "./images/appbrowser3.png";
import spiderChart from "./images/appbrowser.png";
import appbrowser from "./images/appbrowser2.png";
const FeatureBreakOut = () => (
  <section className="features">
    <h2>Usely AI wants you to succeed.</h2>
    <p>
      Usely empowers your Design and User Research teams with cutting-edge tools
      to scientifically evaluate and enhance your software's usability.
    </p>
    <div className="feature-contain">
      <div className="feature-item">
        <img src={heuristics} alt="Usely" />
        <h3>Rubric Ratings A-F</h3>

        <p>
          Usely transformed our approach to usability. The scientific insights
          provided, especially through their Nielsen’s Heuristics Evaluation,
          have been invaluable in refining our user experience. The data
          visualizations made it easy for our team to understand and act on the
          findings.
        </p>

        {/* <button>See In Action</button> */}
      </div>
      <div className="feature-item">
        <img src={spiderChart} alt="Usely" />
        <h3>Measurable Progress Over Time!</h3>
        <p>
          Each heuristic is ranked on a scale from 0 to 5 by default, allowing
          you to quickly identify strengths and pinpoint areas needing
          improvement. You can measure progress over time through your releases.
        </p>
        {/* <button>Listen</button> */}
      </div>
      <div className="feature-item">
        <img src={appbrowser} alt="Usely" />
        <h3>Comparative Analysis</h3>
        <p>
          Compare multiple products or versions or even competitors side-by-side
          to track progress over time and make data-driven decisions that
          enhance user experience in your software.
        </p>
        {/* <button>Read</button> */}
      </div>
    </div>
  </section>
);

export default FeatureBreakOut;
